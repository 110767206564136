/* eslint-disable jsx-a11y/aria-unsupported-elements */
import { useContext, useEffect } from 'react'
import HeroHome from '../components/HeroHome'
import Context from '../context'
import { InferGetServerSidePropsType } from 'next'
import getConfig from 'next/config'
import HeroVariant2 from '../components/HeroVariant2'
import HeroVariant3 from '../components/HeroVariant3'
import Head from 'next/head'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import RewardsBanner from '../components/RewardsBanner'
import AEMContent, { AEMPageType } from '../components/AEMContent'
import RecentlyViewed from '../components/RecentlyViewed'

dayjs.extend(customParseFormat)

const { publicRuntimeConfig } = getConfig()

const Index = ({
  homeData
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { state } = useContext(Context)

  useEffect(() => {
    focus()
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.br_data = window.br_data || {}
      window.br_data.acct_id = publicRuntimeConfig.BR_ID
      window.br_data.ptype = 'homepage'
      window.br_data.test_data = publicRuntimeConfig.BR_TEST_DATA
      window.br_data.user_id = state.user.UID || state.deviceId
      window.br_data.view_id = state.cart.store?.storenumber || publicRuntimeConfig.DEFAULT_STORE;
      (() => {
        const brtrk = document.createElement('script')
        brtrk.type = 'text/javascript'
        brtrk.async = true
        brtrk.src = `//cdn.brcdn.com/v1/br-trk-${publicRuntimeConfig.BR_ID
            }.js?v=${Date.now()}`
        const s = document.getElementsByTagName('script')[0]
        s.parentNode.insertBefore(brtrk, s)
      })()
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setTimeout(() => (window as any)._satellite.track('homepage-qualified-visit', {}), 15_000)
    }
  }, [])

  const renderHome = () => {
    const hero = homeData[0].template.find((x) => x.type === 'hero')
    if (hero) {
      switch (hero.variant) {
        case 'one':
          return <HeroHome data={hero} />
        case 'two':
          return <HeroVariant2 data={hero} />
        case 'three':
          return <HeroVariant3 data={hero} />
      }
    }
  }

  return (
    <div id="main-content" className="home">
      <Head>
        <title>Welcome to pOpshelf</title>
        <meta name="description" content="We believe shopping should be an experience you enjoy. A time to wander. Explore. Linger. A momentary escape just for you..." />
        <meta property="og:image" content="http://cdn.popshelf.com/content/dam/popshelf/brand-images/Popshelf-SM_Logo_Vt_1119x630.png" />
        <meta property="home:published_time" content={homeData[0].modified} />
      </Head>

      <RewardsBanner />

      <section className="home__content">
        <div id="home-hero">{renderHome()}</div>
        <AEMContent template={homeData[0]?.template} page={AEMPageType.home} />
        <div className='home__content-group'>
          <RecentlyViewed/>
        </div>
      </section>
    </div >
  )
}

export default Index

export const getServerSideProps = async () => {
  const res = await fetch(
    `${publicRuntimeConfig.APP_ENDPOINT}/api/ecom/${publicRuntimeConfig.ENV}/home?popkey=${publicRuntimeConfig.POP_KEY}`,
    {
      headers: {
        'X-Pop-App-Token': publicRuntimeConfig.POP_TOKEN_WEB
      }
    }
  )
  const homeData = await res.json()

  return {
    props: {
      homeData
    }
  }
}
