import React, { useState } from 'react'
import Image from 'next/image'
import { defaultLoader } from '../utils/custom-image-loader'

const HeroVariant3 = ({ data }) => {
  const [error, setError] = useState(false)
  const dynamicMediaUrl = data.dynamicMediaUrl || data.image_url
  const dynamicMobileMediaUrl = data.dynamicMediaMobileUrl || data.mobile_image

  return (
    <a data-testid='hero-var3-lnk' href={data.button_url} s_objectid={`${data.title} | N/A | ${data.campaign_name} | ${data.date_reference}`} className="hero-variant-3__container">
      <div data-testid='hero-variant-3' className="hero-home" style={{ display: error ? 'none' : 'block' }}>
        {dynamicMobileMediaUrl && (
          <div className="hero-variant-3__image-wrapper hero-variant-3__image-wrapper--mobile">
            <div className="image-next">
              <Image
                src={dynamicMobileMediaUrl}
                onError={() => setError(true)}
                className="hero-variant-3__image"
                alt={data.alt}
                layout="fill"
                loader={defaultLoader}
              />
            </div>
          </div>
        )}
        {dynamicMediaUrl && (
          <div className="hero-variant-3__image-wrapper hero-variant-3__image-wrapper--desktop">
            <div className="image-next">
              <Image
                src={dynamicMediaUrl}
                onError={() => setError(true)}
                className="hero-variant-3__image"
                alt={data.alt}
                layout="fill"
                loader={defaultLoader}
              />
            </div>
          </div>
        )}
      </div>
    </a>
  )
}

/**
 * Export interface
 */
export default HeroVariant3
