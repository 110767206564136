import React, { useState } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { defaultLoader } from '../utils/custom-image-loader'

const HeroHome = ({ data }) => {
  const [error, setError] = useState(false)
  const router = useRouter()
  const dynamicMediaUrl = data.dynamicMediaUrl || data.image_url
  const dynamicMobileMediaUrl = data.dynamicMediaMobileUrl || data.mobile_image

  return (
    <div data-testid='hero-home' className="hero-home">
      {dynamicMobileMediaUrl && !error && (
        <div className="hero-home__container-image hero-home__container-image--mobile image-next">
          <Image src={dynamicMobileMediaUrl} onError={() => setError(true)} className="hero-home__image" alt={data.alt} layout="fill" loader={defaultLoader}/>
        </div>
      )}
      {dynamicMediaUrl && !error && (
        <div className="hero-home__container-image hero-home__container-image--desktop image-next">
          <Image src={dynamicMediaUrl} onError={() => setError(true)} className="hero-home__image" alt={data.alt} layout="fill" loader={defaultLoader}/>
        </div>
      )}
      <div className="hero-home__content">
        <h1 className="hero-home__title" >
          {data && data.title}
        </h1>
        <p className="hero-home__subtitle">
          {data && data.subtitle}
        </p>
        <button s_objectid={`${data.title} | N/A | ${data.campaign_name} | ${data.date_reference}`} className="hero-home-btn__explore" onClick={() => router.push(data.button_url)}>
            {data && data.button_label}
        </button>
      </div>
    </div>

  )
}

/**
 * Export interface
 */
export default HeroHome
