import { useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { defaultLoader } from '../utils/custom-image-loader'

const HeroVariant2 = ({ data }) => {
  const [error, setError] = useState(false)
  const dynamicMediaUrl = data.dynamicMediaUrl || data.image_url
  const dynamicMobileMediaUrl = data.dynamicMediaMobileUrl || data.mobile_image

  return (
    <div data-testid='hero-variant-2' className="hero-variant-2__wrapper">
      <div data-testid='hero-variant-2-container' className="hero-variant-2__container" style={{ display: error ? 'none' : 'flex' }}>
        <div className="hero-variant-2__container-image">
          {dynamicMobileMediaUrl && (
            <div className="hero-variant-2__container-image--mobile image-next">
              <Image src={dynamicMobileMediaUrl} className="hero-variant-2__image" alt={data.alt} layout="fill" onError={() => setError(true)} loader={defaultLoader}/>
            </div>
          )}
          {dynamicMediaUrl && (
            <div className="hero-variant-2__container-image--desktop image-next">
              <Image src={dynamicMediaUrl} className="hero-variant-2__image" alt={data.alt} layout="fill" onError={() => setError(true)} loader={defaultLoader}/>
            </div>
          )}
          <Link href={data.button_url}>
            <a
              data-testid='hero-variant-2-link'
              s_objectid={`${data.title} | N/A | ${data.campaign_name} | ${data.date_reference}`}
              className="btn__primary btn__primary--full hero-variant-2__button hero-variant-2__button-mobile"
              tabIndex={0}
            >
              {data.button_label}
            </a>
          </Link>
        </div>
        <div className="hero-variant-2__container-details">
          <p className="hero-variant-2__above-text">{data.above_text}</p>
          <p className="hero-variant-2__title">{data.title}</p>
          <p className="hero-variant-2__above-text">{data.subtitle}</p>
          <Link href={data.button_url}>
            <a
              data-testid='hero-variant-2-link'
              s_objectid={`${data.title} | N/A | ${data.campaign_name} | ${data.date_reference}`}
              className="btn__primary hero-variant-2__button hero-variant-2__button-desktop"
              tabIndex={0}
            >
              {data.button_label}
            </a>
          </Link>
        </div>
      </div>
    </div>
  )
}

/**
 * Export interface
 */
export default HeroVariant2
