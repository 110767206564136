import React from 'react'

const TextAlertsSMS = ({ order }) => {
  const formatPhoneForDisplay = (pNumber) => {
    let phone = pNumber.replace(/\D+/g, '')
    const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/)
    if (match) {
      phone = `(${match[1]})${match[2] ? ' ' : ''}${match[2]}${match[3] ? '-' : ''
        }${match[3]}`
    }
    return phone
  }

  if (!order || order.TextAlertPhoneNumber === '0') {
    return null
  }

  return (
    <div className='text-alerts'>
      {!!order.TextAlertPhoneNumber && (order.TextAlertPhoneNumber !== order.PhoneNumber) &&
        <div className='text-alerts__current'>
          <p>Text alerts</p>
          <div className='text-alerts__edit-number'>
            <p>{formatPhoneForDisplay(order.TextAlertPhoneNumber)}</p>
          </div>
        </div>
      }
    </div>
  )
}

export default TextAlertsSMS
