import React, { useContext, useState, useEffect } from 'react'
import Context from '../context'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useAuthAPI } from '../hooks/api.hooks'
import SVG from './SVG'
import { useRouter } from 'next/router'
import ProgressBar from './ProgressBar'

dayjs.extend(customParseFormat)

const BopisTile: React.FC = () => {
  const { state } = useContext(Context)
  const router = useRouter()
  const [orders, setOrders] = useState([])
  const authAPICall = useAuthAPI()

  useEffect(() => {
    const getOrders = async () => {
      if (state.user.appSessionToken) {
        const data = await authAPICall('user', 'orders', 'GET', {}, {})
        if (data?.length) {
          const filtered = data.filter((order) =>
            [1, 3, 5, 6, 8, 9, 11, 12, 15, 27, 31, 26, 32].includes(order.OrderStatusID)
          )
          setOrders([...filtered])
        } else {
          setOrders([])
        }
      }
    }
    getOrders()
  }, [state.user])

  if (orders.length && orders.length > 0) {
    return (
      <div className='widget-bopis'>
        <div className="widget-bopis__status">
          <div className={`${orders.length > 1 && 'widget-bopis__status__bg-icon1-remove'} widget-bopis__status__bg-icon1`}></div>
          <div className="widget-bopis__status__location_icon">
            <SVG name="bopis--location" label="bopis--location" size="md" />
          </div>
          <div className='widget-bopis__status__tile-store'>
            <span id="store-title">
              Your store
            </span>
              <span id="store-location">
                <span>
                {orders[0]?.StoreStreet}, {orders[0]?.StoreCity}, {orders[0]?.StoreState}
                </span>
              </span>

            {state.cart.store?.storenumber && (
              <span id="store-time">
                Closes at {dayjs(
                state.cart.store[
                    `hours_${dayjs().format('ddd').toLowerCase()}`
                ].split(':')[1],
                'hhmm'
              ).format('h:mm A')}
              </span>
            )}
          </div>
        </div>

        {orders.length > 1
          ? (
          <div className="widget-bopis__tile ref-effect" onClick={() => router.push({
            pathname: '/orders',
            query: { clickedElement: 'Home' }
          })}>
            <div className="widget-bopis__progress__bg-icon2"></div>
              <h1> MULTIPLE ORDERS</h1>
          </div>
            )
          : (
              orders.length > 0 && <div className="widget-bopis__progress">
            <div className="widget-bopis__progress__bg-icon2"></div>
            <ProgressBar
                orderStatusID={orders[0].OrderStatusID}
                order={orders[0]}
                isHeading={false}
              />
          </div>
            )
        }
      </div>
    )
  } else {
    return <div></div>
  }
}

export default BopisTile
