import React from 'react'
import getConfig from 'next/config'
import TextAlertsSMS from './TextAlertsSMS'
import QRCode from 'qrcode.react'
import { useRouter } from 'next/router'

const { publicRuntimeConfig } = getConfig()

const ProgressBar = ({ orderStatusID, order, isHeading = true }) => {
  const router = useRouter()
  const orderReceived = () => {
    return <div className={isHeading ? '' : 'widget-section ref-effect'} onClick={() =>
      !isHeading && router.push({
        pathname: `/order/${order?.OrderGUID}`,
        query: { clickedElement: 'Home' }
      })
    }>
    <div className={isHeading ? 'progress-bar__title' : 'widget-title'}>
      {isHeading
        ? <>
        <p>Order Status</p>
        <h1>RECEIVED</h1>
        </>
        : <h1>ORDER RECEIVED</h1>
        }
    </div>
    <div className={isHeading ? 'progress-bar' : 'widget-progress-bar'}>
      <span className={isHeading ? 'progress-bar__step-incomplete progress-bar__dot-start' : 'widget-progress-bar__widget-step-complete widget-progress-bar__widget-dot-end'}></span>
      <span className={isHeading ? 'progress-bar__step-incomplete' : 'widget-progress-bar__widget-step-incomplete'}></span>
      <span className={isHeading ? 'progress-bar__step-incomplete' : 'widget-progress-bar__widget-step-incomplete'}></span>
    </div>
    {publicRuntimeConfig.BOPIS_SMS_ENABLED === 'true' && (<TextAlertsSMS order={order} />)}
  </div>
  }
  const orderPacking = () => {
    return <div className={isHeading ? '' : 'widget-section ref-effect'} onClick={() =>
      !isHeading && router.push({
        pathname: `/order/${order?.OrderGUID}`,
        query: { clickedElement: 'Home' }
      })
    }>
    <div className={isHeading ? 'progress-bar__title' : 'widget-title'}>
    {isHeading
      ? <>
      <p>Order Status</p>
      <h1>PACKING</h1>
      </>
      : <h1>ORDER PACKING</h1>
    }
    </div>
    <div className={isHeading ? 'progress-bar' : 'widget-progress-bar'}>
      <span className={isHeading ? 'progress-bar__step-complete progress-bar__dot-end' : 'widget-progress-bar__widget-step-complete'}> </span>
      <span className={isHeading ? 'progress-bar__step-incomplete' : 'widget-progress-bar__widget-step-complete widget-progress-bar__widget-dot-end'}></span>
      <span className={isHeading ? 'progress-bar__step-incomplete' : 'widget-progress-bar__widget-step-incomplete'}></span>
    </div>
    {publicRuntimeConfig.BOPIS_SMS_ENABLED === 'true' && (<TextAlertsSMS order={order} />)}
  </div>
  }

  const renderShelfInfo = () => {
    return <div className='progress-bar__bin'>
    <h3>Thank you. Grab your bags & go.</h3>
    <div className="progress-bar__bin-location">
      <p>{(order.ShelfInfo?.split('|').length === 1 && order.ShelfInfo?.split('-').length === 1) ? 'BIN' : 'BINS'}</p>
      <h2>{order.ShelfInfo}</h2>
    </div>
    <p className='progress-bar__bin-text'>{(order.ShelfInfo?.split('|').length === 1 && order.ShelfInfo?.split('-').length === 1) ? 'Your order is in the bin above' : 'Your order is in the bins above'}</p>
  </div>
  }

  const renderBin = () => {
    return <div className="widget-bin">
    <h1>Your order is in bin</h1>
    <div className="widget-bin__location">
      <span id='bin-number'>{order.ShelfInfo || '0' }</span>
    </div>
  </div>
  }

  const readyToPickup = () => {
    return <div className={isHeading ? '' : 'widget-section ref-effect'} onClick={() =>
      !isHeading && router.push({
        pathname: `/order/${order?.OrderGUID}`,
        query: { clickedElement: 'Home' }
      })
    }>
    <div className={isHeading ? 'progress-bar__title' : 'widget-title'}>
    {isHeading
      ? <>
      <p>Order Status</p>
      <h1>READY TO PICK UP</h1>
      </>
      : <h1>READY TO PICK UP</h1>
    }
    </div>
    <div className={isHeading ? 'progress-bar' : 'widget-progress-bar'}>
      <span className={isHeading ? 'progress-bar__step-complete' : 'widget-progress-bar__widget-step-complete'}> </span>
      <span className={isHeading ? 'progress-bar__step-complete progress-bar__dot-end' : 'widget-progress-bar__widget-step-complete'}> </span>
      <span className={isHeading ? 'progress-bar__step-incomplete' : 'widget-progress-bar__widget-step-complete widget-progress-bar__widget-dot-end'}></span>
    </div>
    { isHeading ? renderShelfInfo() : renderBin() }
  </div>
  }
  const orderCompleted = () => {
    return <div>
      <div className='progress-bar__title'>
        <p>Order Status</p>
        <h1>COMPLETE</h1>
      </div>
      <div className='progress-bar'>
        <span className='progress-bar__step-complete-green'> </span>
        <span className='progress-bar__step-complete-green'> </span>
        <span className='progress-bar__step-complete-green  progress-bar__dot-end-green'> </span>
      </div>
    </div>
  }
  const orderCanceled = () => {
    return <div>
    <div className='progress-bar__title'>
      <p>Order Status</p>
      <h1>CANCELED</h1>
    </div>
    <div className='progress-bar'>
      <span className='progress-bar__step-cancelled'></span>
      <span className='progress-bar__step-cancelled'></span>
      <span className='progress-bar__step-cancelled'></span>
      <span className='progress-bar__step-cancelled'></span>
    </div>
  </div>
  }
  const storeAssociate = () => {
    return <div>
    <div className='progress-bar__title'>
      <p>Order Status</p>
      <h1>SEE STORE ASSOCIATE</h1>
      <p className='progress-bar__title-description'>We had trouble processing the payment for your order, please see an associate to complete your order and receive your items.</p>
    </div>
    <div className='progress-bar__QR'>
      <p>This is your order's code, if the associate needs to scan it.</p>
      {order?.OrderGUID && <QRCode value={order?.OrderGUID} size={100} />}
    </div>
  </div>
  }
  const renderMain = () => {
    switch (orderStatusID) {
      // RECEIVED
      case 1:
      case 3:
      case 5:
      case 6:
      case 8:
      case 9:
      case 11:
      case 12:
      case 15:
      case 27:
      case 31:
        if (order?.IsCustomerCancelAllowed) {
          return orderReceived()
        } else {
          return orderPacking()
        }
      // READY TO PICK UP
      case 26:
      case 32:
        return readyToPickup()
      // Completed
      case 2:
      case 7:
        return orderCompleted()
      // Canceled
      case 29:
      case 30:
      case 4:
      case 17:
      case 19:
      case 20:
      case 28:
      case 18:
        return orderCanceled()
      default:
        return storeAssociate()
    }
  }
  return (
    <div className={isHeading ? '' : 'widget-section'}>
      <div className={isHeading ? '' : 'widget-section'}>
        {renderMain()}
      </div>
    </div>
  )
}

export default ProgressBar
